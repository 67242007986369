import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, DialogActions, DialogContent, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactTable from 'react-table';
import { Col, Row } from 'reactstrap';
import { CollapsableCard } from '../../../../common/Card/CollapsableCard';
import { CustomPopup } from '../../../../common/Popup/CustomPopup';
import { downloaderFiles } from '../../../../utils/downloaderFiles';
import { exportPdf } from '../../../../utils/exportPdf';
import JournalEntryModal from '../../JournalEntry/component/JournalEntryModal';
import { TrialBalanceColumns } from './columns';
import { Filters } from './components/Filters';
import { useTrialBalance } from './hooks';

export default function TrialBalanceView() {
  const blankFilter = useMemo(() => {
    return {
      start: '',
      end: '',
      page: 0,
      pageSize: 10,
      'general_ledger.credit_amount': { from: null, to: null },
      'general_ledger.debit_amount': { from: null, to: null },
      'account.number': { from: null, to: null },
      detailed: false,
    };
  }, []);

  const [filter, setFilter] = useState(blankFilter);
  const [container, setContainer] = useState();
  const [openJournalEntry, setOpenJournalEntry] = useState({ open: false, account: null });

  const [excelOptions, setExcelOptions] = useState({
    popupOpen: false,
    exporting: false,
    buttonAnchor: null,
    buttonDisabled: false,
  });

  const toggleJournalEntryModal = (row) => {
    if (row?.original) {
      setOpenJournalEntry({ open: true, account: row.original?.number, accountName: row.original?.name });
    } else {
      setOpenJournalEntry({ open: false, account: null, accountName: null });
    }
  };

  useEffect(() => {
    if (excelOptions.exporting) {
      onFilterActionBtn('EXCEL');

      setExcelOptions((prevValue) => ({
        ...prevValue,
        exporting: false,
        buttonDisabled: true,
      }));
    }
  }, [excelOptions.exporting]);

  const columns = useMemo(() => TrialBalanceColumns({ onAccountClick: toggleJournalEntryModal }), []);

  const { data: { data: { accounts: dataSource = [], tenant: tenantData = {} } = {} } = {}, refetch } = useTrialBalance(filter);

  /**
   *
   * @param {*} val
   * @param {*} e
   * @param {*} range {from: number, to: number}
   * @returns
   */
  const handleSelectChange = (val, e, range) => {
    if (e.action === 'select-option') {
      if (range) {
        setFilter((prevValue) => ({
          ...prevValue,
          [e.name]: {
            ...prevValue[e.name],
            [range]: val.value,
          },
        }));
      } else {
        let o = { target: { name: e.name, value: val.value } };
        handleChange(o);
      }
      return;
    }
    if (e.action === 'clear') {
      if (range) {
        setFilter((prevValue) => ({
          ...prevValue,
          [e.name]: {
            ...prevValue[e.name],
            [range]: null,
          },
        }));
      } else {
        let o = { target: { name: e.name, value: null } };
        handleChange(o);
      }
    }
  };

  const handleChange = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        const f = { ...filter, ...e.target.value };
        setFilter(f);
      } else {
        const f = { ...filter, [e.target.name]: e.target.value };
        setFilter(f);
      }
    } catch (error) {
      const f = { ...filter, [e.target.name]: e.target.value };
      setFilter(f);
    }
  };

  const handleCurrencyChange = (value, name, range) => {
    console.log(value, name, range);
    if (range) {
      setFilter((prevValue) => ({
        ...prevValue,
        [name]: {
          ...prevValue[name],
          [range]: +value || null,
        },
      }));
    } else {
      setFilter((prevValue) => ({ ...prevValue, [name]: value }));
    }
  };

  const handleExcelExportClick = (detailed) => {
    setFilter((prevValue) => ({
      ...prevValue,
      detailed: !!detailed,
    }));

    setExcelOptions((prevValue) => ({
      ...prevValue,
      exporting: true,
    }));
  };

  const handleExcelExportCancel = () => {
    setExcelOptions((prevValue) => ({ ...prevValue, popupOpen: false }));
  };

  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      if (e.toString() === 'PDF') exportPdf(container, 'TrialBalance');
      if (e.toString() === 'EXCEL')
        downloaderFiles({
          filter: { ...filter, excel_creation_date: moment().format('YYYY-MM-DD') },
          type: e,
          url: '/me/report/trial-balance',
          cbLoad: () =>
            setExcelOptions((prevValue) => ({
              ...prevValue,
              buttonDisabled: false,
            })),
          name: `${filter.detailed ? 'Detail' : 'Summary'} Trial-Balance ${moment().format('dddd, MMMM DD, YYYY')}`,
        });
    }
    if (e.toString() === 'reset') setFilter(blankFilter);
  };

  const composeDateRange = () => {
    const { start, end } = filter;
    if (start && end) {
      return `Includes activities from ${moment(start).format('MMMM D, YYYY')} to ${moment(end).format('MMMM D, YYYY')}`;
    } else if (start && !end) {
      return `Includes activities from ${moment(start).format('MMMM D, YYYY')} to ${moment().format('MMMM D, YYYY')}`;
    } else if (!start && end) {
      return `Includes activities to ${moment(end).format('MMMM D, YYYY')}`;
    } else {
      return '';
    }
  };

  return (
    <>
      <JournalEntryModal
        {...openJournalEntry}
        onToggle={toggleJournalEntryModal}
        displayFullLedgers
        initialFilters={{
          postingDate: {
            from: filter.start,
            to: filter.end,
          },
        }}
      />

      <CustomPopup
        anchor={excelOptions.buttonAnchor}
        content={
          <MemoizedExcelPopupContent onDownload={handleExcelExportClick} onCancel={handleExcelExportCancel} disabled={excelOptions.buttonDisabled} />
        }
        arrow
        placement='bottom'
        onClose={() =>
          setExcelOptions((prevValue) => ({
            ...prevValue,
            popupOpen: false,
          }))
        }
        open={excelOptions.popupOpen}
      />

      <Row className='mt-5 mb-3'>
        <Col>
          <CollapsableCard headerTitle='Filters' isExpanded={false}>
            <Filters
              filter={filter}
              blankFilter={blankFilter}
              onCurrencyChange={handleCurrencyChange}
              onChange={handleChange}
              onSelectChange={handleSelectChange}
              onSearch={refetch}
            />
          </CollapsableCard>
        </Col>
      </Row>

      <Row className=''>
        <Col className='d-flex justify-content-end'>
          <Tooltip arrow title='Export report to EXCEL'>
            <IconButton
              onClick={(e) => {
                setExcelOptions((prevValue) => ({
                  ...prevValue,
                  buttonAnchor: e.currentTarget,
                  popupOpen: !prevValue.popupOpen,
                }));
              }}
            >
              <i className='fas fa-file-excel' style={{ color: '#686A6B', fontSize: '1.2rem', width: '20px', height: '20px' }} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title='Export report to PDF'>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                e.target.blur();
                onFilterActionBtn('PDF');
              }}
            >
              <PdfIcon />
            </IconButton>
          </Tooltip>
        </Col>
      </Row>

      <div ref={(container) => setContainer(container)} style={{ border: '0px solid black', backgroundColor: 'white' }}>
        <Row>
          <Col className='mb-4'>
            <Row>
              <Col className='d-flex justify-content-between'>
                <Col>
                  <h3 className='title' style={{ paddingTop: '15px', fontWeight: 'bold' }}>
                    Summary Trial Balance
                  </h3>
                </Col>
                <Col className='d-flex justify-content-end'>
                  <h5
                    className='title'
                    style={{
                      paddingTop: '10px',
                      textAlign: 'center',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {moment().format('dddd, MMMM D, YYYY hh:mm A')}
                  </h5>
                </Col>
              </Col>
            </Row>
            <Col>
              <div className='mb-3'>{composeDateRange()}</div>
            </Col>
            <Col>
              <div className='mb-3'>{tenantData?.name}</div>
            </Col>
            <Col>
              <ReactTable data={dataSource || []} columns={columns} showPagination={false} minRows={1} defaultPageSize={1000} />
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
}

const ExcelPopupContent = ({ message, onDownload = () => {}, onCancel = () => {}, disabled }) => {
  return (
    <>
      <Box>
        {message && <DialogContent>{message}</DialogContent>}
        <DialogActions sx={{ padding: '2px' }}>
          <Tooltip arrow title={`Summary Report`}>
            <IconButton disabled={disabled} onClick={() => onDownload(false)}>
              <DownloadIcon color={disabled ? 'disabled' : 'primary'} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={`Detail Report`}>
            <IconButton disabled={disabled} onClick={() => onDownload(true)}>
              <FileDownloadDoneIcon color={disabled ? 'disabled' : 'primary'} />
            </IconButton>
          </Tooltip>
          <IconButton onClick={onCancel}>
            <ClearIcon />
          </IconButton>
        </DialogActions>
      </Box>
    </>
  );
};

const MemoizedExcelPopupContent = React.memo(ExcelPopupContent);
