import { CreditCardOffOutlined, Info as InfoIcon, QuestionMark } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CustomInput } from '../../../../../../../common/CustomInput';
import { DatePickerComponent } from '../../../../../../../common/DatePicker/DatePicker';
import { CustomModal, useCustomModalToggle } from '../../../../../../../common/Dialog/CustomModal';
import { CustomSwitch } from '../../../../../../../common/Inputs/CustomSwitch';
import { VENDOR_CLIENT } from '../../../../../../../constants/clientType.constants';
import { ACH, ACH_NOTES } from '../../../../../../../constants/paymentTypeSource.constants';
import { ALL_TRANSACTIONS, C_TRANSACTIONS, EDIT, V_TRANSACTIONS } from '../../../../../../../constants/permissions.constants';
import { hasPermissionHook } from '../../../../../../../hooks/hasPermission';
import { useReversePayment } from '../transactionApiHooks';
import { notificationDangerCustomTime } from '../../../../../../../utils/toastify';
import { useDetailedTransactions } from '../clientTransactionsHooks';
import { DetailedTransactionsTable } from '../DetailedTransactionsTable';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { CUSTOMER_PAYMENT as PAYMENT_TRANSACTION } from '../../../../../../../constants/documentType.constants';

const PaymentsTableActions = ({ row, clientId, clientType, fromAllTransactionTable }) => {
  const { hasPermission, isPostingDisabled } = hasPermissionHook();
  const params = useParams();

  const { open: isReverseModalOpen, toggleCustomModal: toggleReverseModal } = useCustomModalToggle();

  const [reverseData, setReverseData] = React.useState({});
  const [selectedChildClients, setSelectedChildClients] = React.useState([]);

  const isVendorAchPaymentWithChildren = React.useMemo(() => {
    return (
      row?.original['client.client_type_id'] === VENDOR_CLIENT &&
      [ACH, ACH_NOTES].includes(row?.original?.payment_source?.id) &&
      Boolean(row?.original?.detailed_gls?.length)
    );
  }, [row]);

  const isReconciliatedPayment = React.useMemo(() => Boolean(row?.original?.reconciliated), [row?.original?.reconciliated]);

  const { mutate: reversePaymentMutation } = useReversePayment();

  const calcClientType = React.useMemo(() => {
    if (row?.original['client.client_type_id'] === 1) {
      return 'customers';
    } else {
      return 'vendors';
    }
  }, [row?.original['client.client_type_id']]);

  const normalizedClientType = React.useMemo(() => {
    return params.clientType || clientType || calcClientType;
  }, [params.clientType, clientType, calcClientType]);

  const normalizedClientId = React.useMemo(() => {
    return params.clientId || clientId || row?.original['client.id'];
  }, [params.clientId, clientId, row?.original['client.id']]);

  // if payment and not reversed
  const displayReversePaymentAction = React.useMemo(() => {
    return !row?.original['transactions.payment_reversed'] && row?.original['transactions.document_type_journal_entry_id'] == PAYMENT_TRANSACTION;
  }, [row]);

  const { data: { data: tableData = {} } = {} } = useDetailedTransactions({
    clientId: normalizedClientId,
    clientType: normalizedClientType,
    transactionId: row?.original['transactions.id'],
    enabled: isVendorAchPaymentWithChildren && Boolean(reverseData?.partial_reverse),
  });

  const onOpenApplicationOfPayments = (row) => {
    if (row?.original['document_type.name'].includes('Credit Memo')) {
      window.open(
        `/client/${normalizedClientType}/360/${normalizedClientId}/payment/application-of-payments/${row?.original['transactions.id']}?isCreditMemo=true`,
        '_blank'
      );
    } else if (row?.original['document_type.name'].includes('Refund')) {
      window.open(
        `/client/${normalizedClientType}/360/${normalizedClientId}/payment/application-of-payments/${row?.original['transactions.id']}?isRefund=true`,
        '_blank'
      );
    } else if (row?.original['document_type.name'].includes('Payment')) {
      window.open(
        `/client/${normalizedClientType}/360/${normalizedClientId}/payment/application-of-payments/${row?.original['transactions.id']}?isPayment=true`,
        '_blank'
      );
    } else {
      window.open(
        `/client/${normalizedClientType}/360/${normalizedClientId}/payment/application-of-payments/${row?.original['transactions.id']}`,
        '_blank'
      );
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReverseData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date, name) => {
    setReverseData((prev) => ({ ...prev, [name]: date }));
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setReverseData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleChildClientSelect = (row) => {
    const childClientId = row?.original['client.id'];
    const uniqueSelectedChildren = new Set(selectedChildClients);

    if (childClientId) {
      if (uniqueSelectedChildren.has(childClientId)) {
        uniqueSelectedChildren.delete(childClientId);
      } else {
        uniqueSelectedChildren.add(childClientId);
      }
      setSelectedChildClients(Array.from(uniqueSelectedChildren));
    }
  };

  const reversePaymentOpenPopup = (e) => {
    setReverseData({
      amount: row?.original['transactions.amount'],
      transactionId: row?.original['transactions.id'],
      partial_reverse: isVendorAchPaymentWithChildren && row?.original?.is_partially_reversed,
    });
    toggleReverseModal();
  };

  const onConfirmRevertPayment = () => {
    const datesArray = row?.original?.gls?.map((item) => item.posting_date) || [row?.original['transactions.posting_date']];

    if (isPostingDisabled(datesArray)) {
      notificationDangerCustomTime('You are not allowed to post in this period. Please contact your superior for more info!', 10000);
      return;
    }

    reversePaymentMutation({
      clientId: params.clientId || clientId,
      reversedPaymentAmount: reverseData.partial_reverse ? null : reverseData.amount,
      reversedTransactionId: reverseData.transactionId,
      reverseDate: reverseData.reverse_date,
      partialReverse: reverseData.partial_reverse,
      childClientIds: reverseData.partial_reverse ? selectedChildClients : [],
      createAt: moment().format('YYYY/MM/DD'),
      paymentReversedComment: reverseData.comment_data,
    });
    onCancelReversePayment();
  };

  const onCancelReversePayment = () => {
    setReverseData({});
    setSelectedChildClients([]);
    toggleReverseModal();
  };

  const handleDisable = () => {
    if (fromAllTransactionTable) {
      return !hasPermission(ALL_TRANSACTIONS, EDIT);
    }

    return !hasPermission(clientType === 'customers' ? C_TRANSACTIONS : V_TRANSACTIONS, EDIT);
  };

  if (isReconciliatedPayment) {
    return (
      <div className='d-flex justify-content-center align-items-center'>
        <Tooltip placement='top' title='This Transaction Has Been Reconciliated'>
          <InfoIcon fontSize='small' color='info' sx={{ ':hover': { cursor: 'help' } }} />
        </Tooltip>
      </div>
    );
  }

  return (
    <>
      <>
        <Tooltip arrow placement='top' title={'Application of Payments'} followCursor>
          <IconButton onClick={() => onOpenApplicationOfPayments(row)} disabled={handleDisable()}>
            <CreditScoreIcon />
          </IconButton>
        </Tooltip>
        {displayReversePaymentAction && (
          <Tooltip arrow placement='top' title={'Reverse Payment'} followCursor>
            <IconButton onClick={reversePaymentOpenPopup} disabled={handleDisable()}>
              <CreditCardOffOutlined />
            </IconButton>
          </Tooltip>
        )}
        {row?.original['transactions.payment_reversed_comment'] && (
          <Tooltip arrow placement='top' title={row?.original['transactions.payment_reversed_comment']} followCursor>
            <IconButton>
              <QuestionMark />
            </IconButton>
          </Tooltip>
        )}
      </>
      <CustomModal
        modalTitle='Reverse Payment'
        modalSize={reverseData?.partial_reverse ? 'md' : 'sm'}
        toggleCustomModal={onCancelReversePayment}
        isOpen={isReverseModalOpen}
        actionButtons={
          <div className='d-flex justify-content-between w-100'>
            <Button color='error' onClick={onCancelReversePayment}>
              Cancel
            </Button>
            <Button color='success' onClick={onConfirmRevertPayment}>
              Save
            </Button>
          </div>
        }
      >
        {isVendorAchPaymentWithChildren && (
          <>
            <CustomSwitch
              labelPosition='top'
              label='Partial Reverse'
              name='partial_reverse'
              disabled={isVendorAchPaymentWithChildren && row?.original?.is_partially_reversed} // prevent full reversal if already partially reversed
              checked={reverseData.partial_reverse}
              onChange={handleSwitchChange}
            />
            {reverseData?.partial_reverse && tableData && (
              <DetailedTransactionsTable
                dataSource={tableData}
                selectedChildClients={selectedChildClients}
                onSelectChange={handleChildClientSelect}
              />
            )}
          </>
        )}
        <DatePickerComponent
          onChange={(date) => handleDateChange(date, 'reverse_date')}
          showYearDropdown
          isClearable
          name='reverse_date'
          selected={reverseData.reverse_date}
          placeholder='mm/dd/yyyy'
          dateFormat={'MM/dd/yyyy'}
          label='Reverse Date'
        />
        <CustomInput
          onChange={handleChange}
          label='Comment'
          name='comment_data'
          fullWidth
          variant='outlined'
          type='textarea'
          rows={2}
          placeholder='Description'
        />
      </CustomModal>
      {/* <CustomPopup
        anchor={reverseData.reverseAnchor}
        content={
          <DeleteDepositPopupContent onConfirm={onConfirmRevestPayment} onCancel={() => setReverseData((prev) => ({ reverseAnchor: null }))} />
        }
        arrow
        placement='bottom'
        onClose={() => setReverseData((prev) => ({ reverseAnchor: null }))}
        open={reverseData.openReversePopup}
      /> */}
    </>
  );
};

export { PaymentsTableActions };
