import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { LayersClearOutlined, DoDisturbOnOutlined } from '@mui/icons-material';
import { formatAmount } from '../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../utils/formatDataForTable';

const getEndingBalance = (rowData) => {
  if (rowData.account_type_id == 1) {
    if (
      rowData.is_refund_on_credit_memo ||
      rowData.is_payment_on_negative_refund ||
      rowData.is_negative_payment_on_invoice ||
      rowData.is_negative_payment_on_refund ||
      rowData.is_negative_refund_on_refund ||
      rowData.is_negative_refund_on_negative_refund
    ) {
      return rowData['transactions_due_date.balance'] + rowData['bank_payment_wizard_clearing_invoice.amount'];
    }

    return rowData['transactions_due_date.balance'] - rowData['bank_payment_wizard_clearing_invoice.amount'];
  } else if (rowData.account_type_id == 2 && (rowData.is_refund_on_invoice || rowData.is_negative_refund_on_negative_refund)) {
    return rowData['transactions_due_date.balance'] + rowData['bank_payment_wizard_clearing_invoice.amount'];
  } else {
    return rowData['transactions_due_date.balance'] - rowData['bank_payment_wizard_clearing_invoice.amount'];
  }
};

const getTotalEndingBalance = (rowData) => {
  if (rowData.account_type_id == 1) {
    if (
      rowData.is_refund_on_credit_memo ||
      rowData.is_payment_on_negative_refund ||
      rowData.is_negative_payment_on_invoice ||
      rowData.is_negative_payment_on_refund ||
      rowData.is_negative_refund_on_refund ||
      rowData.is_negative_refund_on_negative_refund
    ) {
      return rowData['transactions_due_date.balance'] + rowData['total_clear_balance'];
    }

    return rowData['transactions_due_date.balance'] - rowData['total_clear_balance'];
  } else if (rowData.account_type_id == 2 && (rowData.is_refund_on_invoice || rowData.is_negative_refund_on_negative_refund)) {
    return rowData['transactions_due_date.balance'] + rowData['bank_payment_wizard_clearing_invoice.amount'];
  } else {
    return rowData['transactions_due_date.balance'] - rowData['total_clear_balance'];
  }
};

export const depositClearingTableColumns = (
  { onClearClick, onCancelClearClick, isClearingDisabled, accountTypeId, isRefund, clientType },
  Actions
) => [
  {
    id: 'transaction_type',
    accessor: (d) => d['transaction_type'],
    Header: (props) => <div className='leftHeader'>Type</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: false,
  },
  {
    id: 'transactions.document_number',
    accessor: (d) => d['transactions.document_number'],
    Header: (props) => <div className='leftHeader'>Document Number</div>,
    Cell: (row) => <div>{row.value}</div>,
    sortable: false,
  },

  {
    id: 'transactions.document_date',
    accessor: (d) => d['transactions.document_date'],
    Header: (props) => <div className='leftHeader'>Date</div>,
    sortable: true,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
  },
  {
    id: 'transactions_due_date.due_date',
    accessor: (d) => d['transactions_due_date.due_date'],
    Header: (props) => <div className='leftHeader'>Due Date</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },

  {
    id: 'transactions_due_date.amount',
    accessor: (d) => d['transactions_due_date.amount'],
    Header: (props) => <div className='leftHeader'>Amount</div>,
    Cell: (row) => <div>{formatAmount(row.value)}</div>,
    sortable: true,
  },
  {
    id: 'transactions_due_date.balance',
    accessor: (d) => d['transactions_due_date.balance'],
    Header: (props) => <div className='leftHeader'>Remaining Balance</div>,
    Cell: (row) => <div>{formatAmount(row.value || 0)}</div>,
    sortable: true,
  },
  {
    id: 'bank_payment_wizard_clearing_invoice.amount',
    accessor: (d) => {
      if (d.is_refund_on_credit_memo && d.account_type_id == 1) {
        return -1 * d['bank_payment_wizard_clearing_invoice.amount'];
      }
      return d['bank_payment_wizard_clearing_invoice.amount'];
    },
    Header: (props) => <div className='leftHeader'>Applied Amount</div>,
    Cell: (row) => <div className={row.value ? 'bg-success' : ''}>{formatAmount(row.value || 0)}</div>,
    sortable: true,
  },
  {
    id: 'end_amm',
    accessor: getEndingBalance,
    Header: (props) => <div className='leftHeader'>Ending Balance</div>,
    Cell: (row) => (
      <div className={row['original']['bank_payment_wizard_clearing_invoice.amount'] ? 'bg-success' : ''}>{formatAmount(row.value || 0)}</div>
    ),
    sortable: false,
  },
  {
    id: 'total_balance',
    accessor: getTotalEndingBalance,
    Header: (props) => <div className='leftHeader'>Total Ending Balance</div>,
    Cell: (row) => (
      <div className={row['original']['total_clear_balance'] !== row['original']['bank_payment_wizard_clearing_invoice.amount'] ? 'bg-warning' : ''}>
        {formatAmount(row.value || 0)}
      </div>
    ),
    sortable: false,
  },
  {
    id: 'bank_payment_wizard_clearing_invoice.date_applied',
    accessor: (d) => d['bank_payment_wizard_clearing_invoice.date_applied'],
    Header: (props) => <div className='leftHeader'>Date Applied</div>,
    Cell: (row) => <div>{formatDateForTable(row.value)}</div>,
    sortable: true,
  },
  {
    id: 'actions',
    sortable: false,
    Header: 'Actions',
    minWidth: 180,
    maxWidth: 180,
    accessor: (row) => {
      const hideClearingButtons = isRefund && row.transaction_type.includes('Invoice') && clientType != 'vendors';
      if (hideClearingButtons) return <></>;

      return (
        <div className='d-flex justify-content-end'>
          <Tooltip title='Apply' placement='top' arrow followCursor>
            <IconButton onClick={() => onClearClick(row)} color='success' disabled={isClearingDisabled}>
              <LayersClearOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title='Cancel Application' placement='top' arrow followCursor>
            <IconButton
              onClick={(e) => onCancelClearClick(e, row)}
              color='error'
              disabled={+row['bank_payment_wizard_clearing_invoice.amount'] === 0}
            >
              <DoDisturbOnOutlined />
            </IconButton>
          </Tooltip>
          {accountTypeId !== 3 && React.cloneElement(Actions, { row: { original: row } })}
        </div>
      );
    },
  },
];
