import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import ReactTable from 'react-table';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { downloaderFiles } from '../../../../utils/downloaderFiles';
import { exportPdf } from '../../../../utils/exportPdf';
import { formatAmount } from '../../../../utils/formatAmout';
import JournalEntryModal from '../../JournalEntry/component/JournalEntryModal';
import { BalanceSheetReportColumns } from '../columns/BalanceSheetReportColumns';
import { ExportButtons } from '../ExportButtons';

export default function BalanceSheetView() {
  const blankFilter = useMemo(() => {
    return { start: '', end: '', page: 0, pageSize: 10 };
  }, []);
  const [dataSource, setDataSource] = useState([]);
  const [filter, setFilter] = useState(blankFilter);
  const [container, setContainer] = useState();
  const [assetTotal, setAssetTotal] = useState(0);
  const [liabilitiesTotal, setLiabilitiesTotal] = useState(0);
  const [liabilitiesEquityTotal, setLiabilitiesEquityTotal] = useState(0);
  const [openJournalEntry, setOpenJournalEntry] = useState({ open: false, account: null });

  const toggleJournalEntryModal = (row) => {
    if (row?.original) {
      setOpenJournalEntry({ open: true, account: row?.original['account.number'], accountName: row?.original['account.name'] });
    } else {
      setOpenJournalEntry({ open: false, account: null, accountName: null });
    }
  };

  const url = useMemo(() => '/me/report/balance-sheet', []);
  const columns = useMemo(() => BalanceSheetReportColumns({ toggleJournalEntryModal }), []);

  useEffect(() => {
    const loadData = () => {
      axios
        .get(url, { params: { ...filter } })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            data.forEach((x) => {
              if (x.account[0] == null) x.account = [];
              if (x.total == 0) {
                x.account = [];
              }
              if (x.account) {
                x.account = x.account.filter((b) => b.total_sum !== 0);
              }
            });
            setTimeout(() => {
              let _assetTotal = 0;
              let _liabilities = 0;
              let netIncome = 0;
              data.forEach((x) => {
                if (x['account_balance_sheet'] === 1 || x['account_balance_sheet'] === 2 || x['account_balance_sheet'] === 6) {
                  _assetTotal += x['total'];
                }
                if (x['account_balance_sheet'] === 3 || x['account_balance_sheet'] === 4 || x['account_balance_sheet'] === 5) {
                  _liabilities += x['total'];
                }
                if (x['account_balance_sheet'] === 99) {
                  netIncome = x['total'];
                }
              });
              const LeEqTotal = _liabilities + (_assetTotal - _liabilities);
              const fakeEquity = [
                {
                  'account.name': 'Net Income',
                  'account.number': 90050,
                  account_balance_sheet: 5,
                  total_sum: netIncome,
                },
                // {
                //   'account.name': 'Retained Earnings',
                //   'account.number': 32000,
                //   account_balance_sheet: 5,
                //   total_sum: _assetTotal - _liabilities - netIncome,
                // },
              ];

              //! temp solution, when ledger filters used this group gets dropped because has no data in db
              const liabilitiesAndEquities = data.filter((data) => data.account_balance_sheet === 5);
              if (!liabilitiesAndEquities.length) {
                data.push({ total: 0, account_balance_sheet: 5, account: [] });
              }

              data.forEach((x) => {
                if (x['account_balance_sheet'] === 5) {
                  x.total += _assetTotal - _liabilities;
                  x.account = [...x.account.filter((acc) => ![90050].includes(acc['account.number'])), ...fakeEquity];
                }
              });
              setAssetTotal(_assetTotal);
              setLiabilitiesTotal(_liabilities);
              setLiabilitiesEquityTotal(LeEqTotal);
              setDataSource(data);
            }, 100);
          }
          setDataSource([]);
        })
        .catch((e) => {
          setDataSource([]);
        });
    };
    loadData();
  }, [filter, url]);

  const onFilterHandler = (e) => {
    try {
      if (e.target.value.constructor === {}.constructor) {
        const f = { ...filter, ...e.target.value };
        setFilter(f);
      } else {
        const f = { ...filter, [e.target.name]: e.target.value };
        setFilter(f);
      }
    } catch (error) {
      const f = { ...filter, [e.target.name]: e.target.value };
      setFilter(f);
    }
  };
  const onFilterActionBtn = (e) => {
    if (e.toString() === 'PDF' || e.toString() === 'EXCEL') {
      if (e.toString() === 'PDF') exportPdf(container, 'ProfitLoss');
      if (e.toString() === 'EXCEL')
        downloaderFiles({
          filter: { ...filter, excel_creation_date: moment().format('YYYY-MM-DD HH:mm:ss') },
          type: e,
          url: '/me/report/balance-sheet',
          cbLoad: () => {},
          name: 'Balance-Sheet',
        });
    }
    if (e.toString() === 'reset') setFilter(blankFilter);
  };

  return (
    <>
      <div className='content-header'>
        <div className='container-fluid'>
          <div className='row mb-1 clearfix'>
            <div className='col-md-4'>
              <h1 className='m-0 text-dark'>Balance Sheet </h1>
            </div>
            <div className='col-md-4'>
              <FormGroup className='col m-0'>
                <Label>As of:</Label>
                <Input type='date' value={filter.end} name='end' onChange={onFilterHandler} />
              </FormGroup>
            </div>
            <div className='col-md-4 d-flex align-items-end'>
              <div>
                <Button
                  color={'default'}
                  className='btn btn-xs shadow'
                  onClick={(e) => {
                    e.preventDefault();
                    e.target.blur();
                    onFilterActionBtn('reset');
                  }}
                >
                  <i className='fas fa-sync-alt'> </i> RESET
                </Button>
              </div>
              <ExportButtons onFilterActionClick={onFilterActionBtn} />
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div ref={(container) => setContainer(container)} style={{ border: '0px solid black', backgroundColor: 'white' }}>
          <Row>
            <Col md={{ size: 10, offset: 1 }}>
              <h3 className='title' style={{ paddingTop: '15px', textAlign: 'center', fontWeight: 'bold' }}>
                Balance Sheet
              </h3>
              <h5
                className='title'
                style={{
                  paddingTop: '10px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {filter?.end ? `As of ${moment(filter.end).format('MMMM D, YYYY')}` : ''}
              </h5>

              <MemoizedReport columns={columns} data={dataSource.find((x) => x.account_balance_sheet === 1)} title='Current Assets Total' id={1} />
              <MemoizedReport columns={columns} data={dataSource.find((x) => x.account_balance_sheet === 2)} title='Fixed Assets Total' id={2} />
              <MemoizedReport columns={columns} data={dataSource.find((x) => x.account_balance_sheet === 6)} title='Other Assets Total' id={6} />

              <div style={{ backgroundColor: '#E8E8E8', display: 'flex', justifyContent: 'space-between', fontSize: '1.5rem', fontWeight: 'bold' }}>
                <span> Assets Total </span>
                <span> {formatAmount(assetTotal)}</span>
              </div>
              <hr style={{ border: 'solid', borderWidth: '1px' }} />

              <MemoizedReport columns={columns} data={dataSource.find((x) => x.account_balance_sheet === 3)} title='Current Liability' id={3} />
              <MemoizedReport columns={columns} data={dataSource.find((x) => x.account_balance_sheet === 4)} title='Long Term Liability' id={4} />

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>Total Liability</h4>
                <h4> {formatAmount(liabilitiesTotal)}</h4>
              </div>
              <hr style={{ border: 'solid', borderWidth: '1px' }} />

              <MemoizedReport columns={columns} data={dataSource.find((x) => x.account_balance_sheet === 5)} title='Equity Total' id={5} />

              <div style={{ backgroundColor: '#E8E8E8', display: 'flex', justifyContent: 'space-between', fontSize: '1.5rem', fontWeight: 'bold' }}>
                <span> Liabilities & Equity </span>
                <span> {formatAmount(liabilitiesEquityTotal)}</span>
              </div>
            </Col>
          </Row>
          <div className='clearfix'> </div>
          <div className='p-3'> </div>
        </div>
      </div>
      <JournalEntryModal
        {...openJournalEntry}
        onToggle={toggleJournalEntryModal}
        displayFullLedgers
        initialFilters={{
          postingDate: {
            from: filter.start,
            to: filter.end,
          },
        }}
      />
    </>
  );
}

const Report = ({ data, title, columns }) => {
  if (!data) {
    data = { total: 0, account: [] };
  }

  const NullComponent = () => null;

  return (
    <>
      <div>
        <div>
          <h4>{title}</h4>
        </div>
      </div>

      <ReactTable data={data.account} columns={columns} showPagination={false} minRows={1} NoDataComponent={NullComponent} />
      <div className='d-flex justify-content-end'>
        <h4>{formatAmount(data.total)}</h4>
      </div>
      <div
        style={{
          backgroundColor: '#E8E8E8',
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      ></div>
    </>
  );
};

const MemoizedReport = React.memo(Report);
