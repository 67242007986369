import React from 'react';
import { Link } from 'react-router-dom';
import { formatAmount } from '../../../../utils/formatAmout';
import { formatDateForTable } from '../../../../utils/formatDataForTable';

const cellHeaderStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' };

export const DepreciationReportTableColumns = ({}) => {
  return [
    {
      accessor: 'unit_number',
      Header: 'Unit #',
      headerStyle: cellHeaderStyle,
      Cell: (row) => {
        return (
          <Link target='_blank' to={`fixed-assets/${row?.original?.id}/transactions`}>
            {row?.value}
          </Link>
        );
      },
    },
    {
      accessor: 'unit_year',
      Header: 'Year',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row?.original?.unit_year,
      width: 65,
    },
    {
      accessor: 'unit_manufacturer',
      Header: 'Make',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row?.original?.unit_manufacturer,
      width: 110,
    },
    {
      accessor: 'unit_model',
      Header: 'Model',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row?.original?.unit_model,
    },
    {
      accessor: 'vin',
      Header: 'Vin #',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row?.value,
      minWidth: 200,
    },
    {
      accessor: 'status',
      Header: 'Status',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row?.value,
      minWidth: 200,
    },
    {
      accessor: 'branch_name',
      Header: 'Branch',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row?.original?.branch_name,
    },
    {
      accessor: 'lien_holder',
      Header: 'Lien Holder',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row?.original?.lien_holder,
    },
    {
      accessor: 'truck_type',
      Header: 'Truck Type',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row?.original?.unit_truck_type,
      width: 180,
    },
    {
      accessor: 'acquisition_cost',
      Header: 'Purchase Cost',
      headerStyle: cellHeaderStyle,
      width: 120,
      Cell: (row) => formatAmount(row?.value),
    },
    {
      accessor: 'begining_depreciation_date',
      Header: 'Depreciation Start Date',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatDateForTable(row?.value),
      width: 110,
    },
    {
      accessor: 'end_depreciation_date',
      Header: 'Depreciation End Date',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatDateForTable(row?.value),
      width: 110,
    },
    {
      accessor: 'monthly_depreciation_expense',
      Header: 'Monthly Depreciation Expense',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatAmount(row?.value),
      width: 110,
    },
    {
      accessor: 'year_to_date_depreciation_expense',
      Header: 'Year To Date Depreciation Expense',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatAmount(row?.value),
      width: 110,
    },
    {
      accessor: 'accumulated_depreciation',
      Header: 'Accumulated Depreciation',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatAmount(row?.value),
      width: 110,
    },
    {
      accessor: 'salvage_value',
      Header: 'Salvage Value',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatAmount(row?.value),
      width: 110,
    },
    {
      accessor: 'depreciation_period',
      Header: 'Useful Life',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row.value,
    },
    {
      accessor: 'net_book_value',
      Header: 'Net Book Value',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatAmount(row?.value),
      width: 110,
    },
    {
      accessor: 'sales_date',
      Header: 'Disposal Date',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatDateForTable(row?.value),
      width: 110,
    },
    {
      accessor: 'invoice_number',
      Header: 'Invoice Number',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row.value,
      width: 110,
    },
    {
      accessor: 'customer',
      Header: 'Customer',
      headerStyle: cellHeaderStyle,
      Cell: (row) => row.value,
      width: 110,
    },
    {
      accessor: 'sold_for',
      Header: 'Selling Price',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatAmount(row?.value),
      width: 120,
    },
    {
      accessor: 'gain_loss_on_sale',
      Header: 'Gain/Loss on Sale',
      headerStyle: cellHeaderStyle,
      Cell: (row) => formatAmount(row?.value),
      width: 120,
    },
  ];
};
